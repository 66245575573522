import React from 'react'

function ListIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" focusable="false" viewBox="0 0 24 24" width={30} height={30} fill='#fff' color="rgb(255, 255, 255)">
            <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zM9 17H7v-5h2v5zm4 0h-2v-3h2v3zm0-5h-2v-2h2v2zm4 5h-2V7h2v10z">
            </path>
        </svg>
    )
}

export default ListIcon