import React from 'react'
import { ROUTES } from '../../constants/routes'
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom'
import LayoutComponent from './layouts/LayoutComponent'
import HomePage from './pages/HomePage'


const router = createBrowserRouter(
    createRoutesFromElements(
        (
            <>
                <Route path={ROUTES.DASHBOARD} element={<LayoutComponent />}>
                    <Route index element={<HomePage />} />
                </Route>
            </>
        )
    )
)

function RouterComponent() {
    return (
        <RouterProvider router={router} />
    )
}

export default RouterComponent
