import RouterComponent from './app/components/navigation/RouterComponent';

// const preventPullToRefresh = (e) => {
//   // Prevent pull-to-refresh if not within a scrollable area
//   if (e.target.closest('.scrollable')) return;

//   if (window.scrollY === 0) {
//     e.preventDefault();
//   }
// };
// document.addEventListener('touchmove', preventPullToRefresh, { passive: false });


function App() {

  // const [theme] = useState('light');// dark
  return (
    <main className={`light text-foreground bg-background `}>
      <RouterComponent />
    </main>
  );
}

export default App;
