import React from 'react'
import HeaderComponent from '../../langing/HeaderComponent'
import HeroComponent from '../../langing/HeroComponent'
import VideoComponent from '../../langing/VideoComponent'
import AdvantagesComponent from '../../langing/AdvantagesComponent'
import ReviewsComponent from '../../langing/ReviewsComponent'
import TabsComponent from '../../langing/TabsComponent'
import FAQComponent from '../../langing/FAQComponent'
import DarkComponent from '../../langing/DarkComponent'
import FormComponent from '../../langing/FormComponent'
import ContactsComponent from '../../langing/ContactsComponent'

function HomePage() {

    return (
        <div className='flex flex-col flex-nowrap'>
            <HeaderComponent />
            <HeroComponent />
            <VideoComponent />
            <AdvantagesComponent />
            <ReviewsComponent />
            <TabsComponent />
            <FAQComponent />
            <DarkComponent />
            <FormComponent />
            <ContactsComponent />
        </div>
    )
}

export default HomePage