import React from 'react'
import LogoTopComponent from '../element/LogoTopComponent'

function HeaderComponent() {
    return (
        <div className='p-4'>
            <LogoTopComponent />
        </div>

    )
}

export default HeaderComponent