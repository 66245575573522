import React from 'react'

function CarouselItemComponent({ name, text, image, className = "" }) {
    return (
        <div className={`carousel-item flex flex-col bg-blue b-blue w-[350px] rounded-[24px] ${className}`}>
            <div className='flex flex-row items-center gap-3 p-5'>
                <img src={image} alt="" className='w-12 h-12' />
                <div className='flex flex-row items-center justify-center'>{name}</div>
            </div>
            <div className='flex flex-col items-left justify-items-start bg-white m-3 p-5 rounded-[20px] grow'>
                <div>
                    <img src="images/stars.svg" alt="" />
                </div>

                <div className='mt-5 text-lg'>
                    {text}
                </div>
            </div>
        </div>
    )
}

export default CarouselItemComponent