import "@fontsource/readex-pro/600.css";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";
import "@fontsource/poppins/800.css";
import '../../../../App.css';
import React from 'react'
import { Toaster } from 'react-hot-toast'
import { Outlet } from 'react-router-dom'
import FooterComponent from '../FooterComponent';
import { motion } from "framer-motion"


const LayoutComponent = () => {

    return (
        <>
            <Toaster toastOptions={{ className: 'toast-style' }} />
            <div className="App wrapper w-full" >
                <motion.div className="w-full">
                    <div className="max-w-[1200px] mx-auto">
                        <Outlet />
                    </div>
                    <FooterComponent />
                </motion.div>
            </div>
        </>
    )
}

export default LayoutComponent