import React from 'react'

function BlueButtonComponent({ text }) {
    return (
        <div className='w-full flex flex-col justify-center items-center p-3 text-centers'>
            <a className='text-center' href='https://ai.medup.tech'><div className=' text-md sm:text-xl font-bold  bg-my-blue px-5 sm:px-5 py-5 sm:py-4 rounded-lg text-white w-full sm:w-64 cursor-pointer text-centers' >{text}</div></a>
        </div>
    )
}

export default BlueButtonComponent