import React from 'react'

function LogoTopComponent() {
    return (
        <>
            <div className='flex items-center'>
                <img src="images/logo.svg" className='mx-2 w-[30px]  h-[30px] sm:w-[40px] sm:h-[40px] ' alt="" />
                <span className='text-3xl sm:text-[32px] font-bold header-font'>Med<span className='text-my-blue'>Up</span></span>
            </div>
            <div className='text-[14px] sm:text-sm text-primary-300 pt-2 mx-2'>AI - Powered Medical Encyclopedia</div>
        </>
    )
}

export default LogoTopComponent