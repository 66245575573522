import { Tab, Tabs } from '@nextui-org/react';
import React from 'react'

function TabsComponent() {
    let tabs = [
        {
            id: "photos",
            label: "Mission",
            image: "images/tab3.webp",
            content:
                `<p><b>Our mission is to make high-quality medical information freely accessible to&nbsp;everyone, everywhere.</b></p>
                <p class='mt-3'>We believe that trusted, accurate health information can improve lives and should be available to everyone—regardless of&nbsp;financial means, medical knowledge, or&nbsp;location.</p>

                <p class='mt-3'>By leveraging new technologies, we provide access to verified data—from clinical guidelines to&nbsp;research from the&nbsp;WHO and&nbsp;national institutions—in an&nbsp;understandable way. </p>
                <p class='mt-3'>With <b>MedUp</b>, you can quickly access relevant information, fostering clearer communication with healthcare providers and&nbsp;making each step of&nbsp;the&nbsp;healthcare journey more efficient and&nbsp;accessible.</p>`,
        },
        {
            id: "music",
            label: "Technology",
            image: "images/tab2.jpeg",
            content:
                `<p><b>MedUp uses advanced artificial intelligence technology to&nbsp;analyze and structure medical information from trusted sources such as&nbsp;clinical guidelines, WHO data and&nbsp;national institute studies.</b></p>
                <p class='mt-3'>Our system is trained to replicate the action protocols that physicians use, allowing us&nbsp;to&nbsp;provide users with accurate and&nbsp;understandable information.</p> 
                <p class='mt-3'>With support for voice, text and visual input, Eccex makes the process of&nbsp;searching for&nbsp;medical information convenient, fast and&nbsp;accessible to&nbsp;everyone.</p>`,
        },
        {
            id: "videos",
            label: "Safety",
            image: "images/tab2.webp",
            content:
                `<p><b>MedUp is currently in&nbsp;beta testing and&nbsp;is&nbsp;continuously improving.</b> The service is&nbsp;designed to&nbsp;comply with FDA, HIPAA, and&nbsp;GDPR standards, and&nbsp;is&nbsp;currently undergoing the&nbsp;verification and&nbsp;registration process</p>
                <p class='mt-3'>We prioritize user privacy and data security, adhering to&nbsp;the&nbsp;highest industry standards.</p>
                <p class='mt-3'>The system's accuracy is reviewed by real doctors and currently stands at 92%.</p><p class='mt-3'>This service is&nbsp;for&nbsp;educational purposes only and&nbsp;is&nbsp;not&nbsp;a&nbsp;substitute for&nbsp;a&nbsp;doctor’s consultation.</p>`,
        },
    ];
    return (
        <div className="flex flex-col items-center justify-center mx-4 xl:mx-0 md:mt-14 px-0 lg:px-6 pt-10">
            <Tabs
                size='lg'
                aria-label="Dynamic tabs"
                items={tabs}
                radius='lg'
                variant='bordered'
                classNames={{
                    panel: "bg-blue b-blue rounded-[16px] mt-5",
                    base: "bg-blue b-blue rounded-[16px]",
                    tab: "text-primary-800",
                    tabContent: "text-primary-300"
                }}>
                {(item) => (
                    <Tab key={item.id} title={item.label}>
                        <div className='p-5 flex flex-row'>
                            <img className='self-stretch object-cover object-center w-1/4 h-f rounded-lg hidden lg:block' src={item.image} alt="" />
                            <div className='px-0 lg:px-6 lg:text-lg' dangerouslySetInnerHTML={{ __html: item.content }} />
                        </div>
                    </Tab>
                )}
            </Tabs>
        </div>
    )
}

export default TabsComponent