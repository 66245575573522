import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import TitleComponent from '../element/TitleComponent';
import CarouselItemComponent from '../element/CarouselItemComponent';

function ReviewsComponent() {
  const originalItems = [
    { id: 1, image: "images/rzOSl3PF4tPFKRSHw6g7mGWZUA.png", name: "James Thomas", text: "I liked that it asked clarifying questions like a real doctor. As a result, it helped me realize that I needed to make an appointment with an endocrinologist right away" },
    { id: 2, image: "images/UQEQvh5Xc4qHhXpFEhC4DU9Y6ok.png", name: "Sarah Lee", text: "I didn't want to see a doctor because it was expensive, and the problem wasn't serious. The service provided all the info I needed to handle it myself." },
    { id: 3, image: "images/n1Hn73TJWxY8IgEUSXJ1esGyaZw.png", name: "David Leo", text: "Panic relief! The service told me what to do for my mom's shortness of breath and which doctor to see. While we were waiting for the appointment, I didn't worry so much" },
    { id: 4, image: "images/5zvN25A6Yiu5jz68AzgolcWsk.png", name: "Emily Roger", text: "Finally! No more googling or wondering what could be causing the symptoms. In 2 minutes I got all the information, including possible medications and advice on who to go to." },
    { id: 5, image: "images/5EAnJjwHX4ravibR5tgTwvrg5o.png", name: "Milena K", text: "The convenience is top notch! I used voice input and the service understood me perfectly. I am sure it will be useful for anyone who wants to understand their condition before seeing a doctor" },
    { id: 6, image: "images/Q7C0Ch2x3aA1rw8eFyolnhU5LGc.png", name: "Lisa Mathew", text: "It was nice to see that the service relies only on verified medical data. This is important to me, because on the internet you can often come across unreliable information" },
  ];

  const [translateX, setTranslateX] = useState(0); // State for scrolling position
  const [items, setItems] = useState(originalItems); // Current carousel items
  const scrollSpeed = 1; // Scrolling speed (pixels per frame)
  const itemWidth = 370; // Width of a single carousel item

  useEffect(() => {
    const animateScroll = () => {
      setTranslateX(prev => {
        const nextPosition = prev - scrollSpeed;

        // If we've scrolled past one item's width, rotate the items
        if (Math.abs(nextPosition) >= itemWidth) {
          setItems(prevItems => {
            const updatedItems = [...prevItems];
            updatedItems.push(updatedItems.shift()); // Move the first item to the end
            return updatedItems;
          });

          return 0; // Reset the translateX to 0 to prevent "jumping"
        }

        return nextPosition; // Continue scrolling
      });
    };

    const intervalId = setInterval(animateScroll, 30); // Smooth scrolling
    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [itemWidth]);

  return (
    <div className="flex flex-col items-center justify-center pt-14">
      <TitleComponent>What Our Users Say</TitleComponent>
      <div
        className="carousel relative items-start justify-start mt-10 gap-6 overflow-hidden w-full"
        style={{
          maskImage: "linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 9.5%, rgb(0, 0, 0) 90.5%, rgba(0, 0, 0, 0) 100%)",
          WebkitMaskImage: "linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 9.5%, rgb(0, 0, 0) 90.5%, rgba(0, 0, 0, 0) 100%)",
        }}
      >
        <motion.div
          className="flex space-x-6"
          style={{
            transform: `translateX(${translateX}px)`,
          }}
          animate={{ x: translateX }}
          transition={{ ease: "linear", duration: 0 }}
        >
          {items.map((item, index) => (
            <CarouselItemComponent
              key={index}
              className="carousel-item flex-shrink-0"
              image={item.image}
              name={item.name}
              text={item.text}
            />
          ))}
        </motion.div>
      </div>
    </div>
  );
}

export default ReviewsComponent;
