import { Avatar, AvatarGroup } from '@nextui-org/react'
import React from 'react'
import BlueButtonComponent from '../buttons/BlueButtonComponent'

function HeroComponent() {
    return (
        <div className='max-w-screen-lg mx-auto text-center flex flex-col items-center pt-10 pb-20'>
            <h1 class="text-3xl sm:text-5xl font-bold flex flex-wrap justify-center items-center">
                <span>
                    <span >G</span>
                    <span >e</span>
                    <span >t</span>
                </span>
                <span className='p-2'> </span>
                <span className="text-my-blue" >
                    <span >
                        <span >T</span>
                        <span >r</span>
                        <span >u</span>
                        <span >s</span>
                        <span >t</span>
                        <span >e</span>
                        <span >d</span>
                    </span>
                </span>
                <span className='p-2'> </span>
                <span>
                    <span >H</span>
                    <span >e</span>
                    <span >a</span>
                    <span >l</span>
                    <span >t</span>
                    <span >h</span>
                </span>
                <span className='p-2'> </span>
                <span >
                    <span >I</span>
                    <span >n</span>
                    <span >f</span>
                    <span >o</span>
                    <span >r</span>
                    <span >m</span>
                    <span >a</span>
                    <span >t</span>
                    <span >i</span>
                    <span >o</span>
                    <span >n</span>
                </span>
            </h1>

            <h2 class="framer-text flex text-xl sm:text-3xl flex-wrap justify-center items-center ">
                <span >
                    <span >B</span>
                    <span >a</span>
                    <span >c</span>
                    <span >k</span>
                    <span >e</span>
                    <span >d</span>
                </span>
                <span className='pl-2'> </span>
                <span >
                    <span >b</span>
                    <span >y</span>
                </span>
                <span className='pl-2'> </span>
                <span >
                    <span >W</span>
                    <span >H</span>
                    <span >O</span>
                </span>
                <span className='pl-2'> </span>
                <span >
                    <span >a</span>
                    <span >n</span>
                    <span >d</span>
                </span>
                <span className='pl-2'> </span>
                <span >
                    <span >C</span>
                    <span >l</span>
                    <span >i</span>
                    <span >n</span>
                    <span >i</span>
                    <span >c</span>
                    <span >a</span>
                    <span >l</span>
                </span>
                <span className='pl-2'> </span>
                <span >
                    <span >G</span>
                    <span >u</span>
                    <span >i</span>
                    <span >d</span>
                    <span >e</span>
                    <span >l</span>
                    <span >i</span>
                    <span >n</span>
                    <span >e</span>
                    <span >s</span>
                </span>
            </h2>
            <div className='flex flex-col justify-center items-center max-w-[700px] border b-blue bg-blue p-4 rounded-lg m-5 mt-10 '>
                <h3 className='text-md sm:text-lg font-bold text-secondary-600'>Describe symptoms, answer follow-up questions & get detailed information</h3>
                <div className='block md:flex md:flex-row text-left py-4'>
                    <div className='flex my-2 items-center'>
                        <div style={{ backgroundImage: "url(images/icon-head.png)", backgroundRepeat: "no-repeat" }} src="" className='mx-2 bg-contain bg-center flex-initial w-28 min-h-16' alt="" />
                        <div>Symptoms and possible conditions</div>
                    </div>
                    <div className='flex my-2 items-center'>
                        <div style={{ backgroundImage: "url(images/icon-pill.png)", backgroundRepeat: "no-repeat" }} src="" className='mx-2 bg-contain bg-center flex-initial w-28 min-h-16' alt="" />
                        <div>Trusted treatments and medications</div>
                    </div>
                    <div className='flex my-2 items-center'>
                        <div style={{ backgroundImage: "url(images/icon-doc.png)", backgroundRepeat: "no-repeat" }} src="" className='mx-2 bg-contain bg-center flex-initial w-28 min-h-16' alt="" />
                        <div>Recommended doctors and tests</div>
                    </div>
                </div>
            </div>

            <BlueButtonComponent text="Get Started" />

            <div className='flex justify-center items-center b-blue p-[3px] rounded-full px-2 my-4 text-xs sm:text-base bg-blue'>
                <AvatarGroup size="sm" >
                    <Avatar style={{ border: "1px solid #ffffff" }} src="images/VLgy4LHFmPRcroCjnlXTid9rvcc.png" />
                    <Avatar style={{ border: "1px solid #ffffff" }} src="images/UQEQvh5Xc4qHhXpFEhC4DU9Y6ok.png" />
                    <Avatar style={{ border: "1px solid #ffffff" }} src="images/UENPQ6M5gthstqeACgcVeN76TZk.png" />
                </AvatarGroup>
                <span className='pl-2 text-my-darker-blue'>Loved by over 10.000 users</span>
            </div>

        </div>
    )
}

export default HeroComponent