import React from 'react'

function ListAdvantagesItemComponent({ icon, title, text }) {
    return (
        <div className='p-5 bg-blue b-blue rounded-[16px] flex flex-col items-center  text-center'>
            <div className='bg-[#193447] rounded-lg flex gap-3 h-min w-min p-3 m-2 items-center justify-center'>{icon}</div>
            <div className='m-2 text-xl font-bold'>{title}</div>
            <div className='text-lg' dangerouslySetInnerHTML={{ __html: text }} />
        </div>
    )
}

export default ListAdvantagesItemComponent