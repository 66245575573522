import React from 'react'
import ListAdvantagesItemComponent from '../element/ListAdvantagesItemComponent'
import TickRoundIcon from '../../assets/TickRoundIcon'
import DollarIcon from '../../assets/DollarIcon'
import FastForwardIcon from '../../assets/FastForwardIcon'
import DocumentsIcon from '../../assets/DocumentsIcon'
import AdvantagesTickComponent from '../element/AdvantagesTickComponent'

function AdvantagesComponent() {
    return (
        <>
            <div className='flex max-w-[1162px] justify-center items-center flex-col-reverse lg:flex-row py-[30px] md:py-[65px]'>
                <div className='flex-1 w-full lg:w-1/12 flex-shrink-0 grid flex-0 gap-5 grid-flow-row-dense grid-cols-auto  lg:grid-cols-2 xl:grid-cols-2 h-auto justify-center overflow-x-hidden p-0 relative '>

                    <ListAdvantagesItemComponent icon={<TickRoundIcon />} title={"Reliable Information"} text={"Verified sources, not&nbsp;Google or&nbsp;GPT"} />
                    <ListAdvantagesItemComponent icon={<DollarIcon />} title={"Free of Charge"} text={"Access quality healthcare info with no&nbsp;fees"} />
                    <ListAdvantagesItemComponent icon={<FastForwardIcon />} title={"Fast and Accessible"} text={"Get quick answers, no&nbsp;waiting"} />
                    <ListAdvantagesItemComponent icon={<DocumentsIcon />} title={"Expert Knowledge"} text={`Trusted medical info without being a&nbsp;specialist`} />
                </div>
                <div className='flex-1 flex-shrink-0 max-w-[520px] flex flex-col items-start justify-center flex-nowrap gap-3 min-h-min w-full lg:w-1/5 p-0 overflow-visible pl-0 lg:pl-11 py-10'>
                    <div className='font-bold text-4xl sm:text-[45px] leading-tight py-5 text-center lg:text-start'>You can easily find <span className='text-my-blue'>reliable health info</span> that covers all treatment questions</div>
                    <div className='flex flex-wrap gap-2 justify-center lg:justify-start'>
                        <AdvantagesTickComponent text={"100% Free"} />
                        <AdvantagesTickComponent text={"Instant results"} />
                        <AdvantagesTickComponent text={"AI-powered system"} />
                        <AdvantagesTickComponent text={"Up-to-date information"} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default AdvantagesComponent