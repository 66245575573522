import React from 'react'

function ListVideoItem({ icon, text }) {
    return (
        <>
            <div className='flex flex-1 flex-grow flex-col items-center text-center p-3 w-full sm:w-full md:w-1/2 lg:w-1/4 sm:min-w-[300px] lg:min-w-[200px]'>
                <div className='bg-[#193447] rounded-full flex gap-3 h-min w-min p-3 items-center justify-center my-3'>{icon}</div>
                <div>{text}</div>
            </div>
        </>
    )
}

export default ListVideoItem