import React from 'react'

function DarkComponent() {
  return (
    <div className='w-full flex flex-col justify-center items-center'>
      <div className='max-w-[1000px] w-full' style={{ backgroundImage: "url(images/bg-blue-wave.png)" }} >
        <div>
          MedUp
        </div>
        <div>
          Your Free AI-Powered
        </div>
        <div>
          Medical Encyclopedia
        </div>
        <a className='text-center' href='https://ai.medup.tech'><div className=' text-md sm:text-xl font-bold  bg-my-blue px-5 sm:px-5 py-5 sm:py-4 rounded-lg text-white w-full sm:w-64 cursor-pointer text-centers' >hi</div></a>
      </div>
    </div>
  )
}

export default DarkComponent