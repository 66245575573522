import React from 'react'

function FooterComponent({ className = "" }) {
    return (
        <footer className={`site-footer flex justify-center items-center w-full ${className}`}>
            <div className="container mx-auto px-4 text-center">
                <p className="text-md text-gray-500" >&copy; 2024 MedUp</p>
            </div>
        </footer>
    )
}

export default FooterComponent