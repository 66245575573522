import React, { useEffect, useRef, useState } from 'react'
import ListIcon from '../../assets/ListIcon';
import TickIcon from '../../assets/TickIcon';
import GearIcon from '../../assets/GearIcon';
import ShieldIcon from '../../assets/ShieldIcon';
import ListVideoItem from '../element/ListVideoItem';
import DividerComponent from '../element/DividerComponent';
import BlueButtonComponent from '../buttons/BlueButtonComponent';

function VideoComponent() {
  const desktopVideoRef = useRef(null);
  const mobileVideoRef = useRef(null);
  const [isDesktopVisible, setIsDesktopVisible] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);


  // Handle screen resize for responsive video
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Intersection Observer for desktop video
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsDesktopVisible(entry.isIntersecting);
      },
      {
        threshold: 0.5, // Trigger when 50% of the video is visible
      }
    );

    const targetVideo = isMobileView ? mobileVideoRef.current : desktopVideoRef.current;

    if (targetVideo) {
      observer.observe(targetVideo);
    }

    return () => {
      if (targetVideo) {
        observer.unobserve(targetVideo);
      }
    };
  }, [isMobileView]);

  useEffect(() => {
    const desktopVideo = desktopVideoRef.current;
    const mobileVideo = mobileVideoRef.current;

    if (isDesktopVisible) {
      if (isMobileView) {
        mobileVideo?.play();
        desktopVideo?.pause();
      } else {
        desktopVideo?.play();
        mobileVideo?.pause();
      }
    } else {
      desktopVideo?.pause();
      mobileVideo?.pause();
    }
  }, [isDesktopVisible, isMobileView]);

  return (
    <>
      <div
        className="rounded-t-[50px] rounded-b-3xl flex flex-col justify-center max-w-[1162px] mt-36 text-white"
        style={{
          backgroundImage: 'url(images/bg-blue-wave.png)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div className="w-full flex flex-col items-center">
          {!isMobileView ? (
            <video
              ref={desktopVideoRef}
              autoPlay
              muted
              src="video/desktop.mp4"
              loop
              preload="auto"
              poster="video/desktop.png"
              playsInline
              className="video-desktop"
              style={{
                cursor: 'auto',
                width: '800px',
                height: '471px',
                position: 'relative',
                top: "-150px",
                objectFit: 'contain',
                objectPosition: 'center',
                marginBottom: '-150px', // Adjust alignment with margin
              }}
            />
          ) : (
            <video
              ref={mobileVideoRef}
              muted
              src="video/mobile.mp4"
              loop
              preload="auto"
              poster="video/mobile.png"
              playsInline
              className="video-mobile"
              style={{
                cursor: 'auto',
                width: '283px',
                height: '431px',
                position: 'relative',
                top: "-150px",
                objectFit: 'contain',
                objectPosition: 'center',
                marginBottom: '-150px', // Adjust alignment with margin
              }}
            />
          )}

          <div className="relative flex items-center justify-center p-3 m-3 sm:m-6 bg-[#193447] rounded-xl">
            <div className="m-3 font-bold text-xl uppercase">important:</div>
            <div className="m-3 text-base sm:text-lg">
              <p className="mb-2">
                <b>MedUp</b> offers educational information only.
              </p>
              <p>For personalized medical advice, please consult a healthcare professional.</p>
            </div>
          </div>

          <div className="relative flex justify-center m-3 sm:m-7 rounded-xl gap-3 p-3 flex-wrap flex-col sm:flex-row">
            <ListVideoItem icon={<ListIcon />} text="The system’s algorithm follows diagnostic and treatment protocols" />
            <DividerComponent />
            <ListVideoItem icon={<TickIcon />} text="Database includes only verified sources with proof links in results" />
            <DividerComponent />
            <ListVideoItem icon={<GearIcon />} text="Simple interface with voice, text, or photo options" />
            <DividerComponent />
            <ListVideoItem icon={<ShieldIcon />} text="Results include key info and links to medications and doctors" />
          </div>
        </div>

      </div>
      <div className='pt-5'>
        <BlueButtonComponent text="Start chat" />
      </div>

    </>
  )
}

export default VideoComponent