import React from 'react'
import TitleComponent from '../element/TitleComponent'
import AccordionItemComponent from '../element/AccordionItemComponent'

function FAQComponent() {
    const items = [
        { id: 1, title: "How does the service work?", text: "MedUp analyzes your described symptoms using AI, asks clarifying questions, and provides information about possible conditions, treatment options, recommended medications, and doctors you should consult." },
        { id: 2, title: "Is it safe to use this service?", text: "All information is based on verified medical sources, such as treatment protocols, scientific articles, and clinical guidelines. However, this service does not replace a doctor’s consultation and should only be used for educational or informational purposes." },
        { id: 3, title: "Can the service make a diagnosis?", text: "No, our service does not make diagnoses. It provides information about possible conditions based on your symptoms. For an accurate diagnosis, please consult a qualified doctor." },
        { id: 4, title: "Is the service free to use?", text: "Yes, the basic functionality is free. Advanced features, such as detailed analyses or integration with medical institutions, may require payment." },

    ]
    return (
        <div className='flex flex-col justify-center items-center pt-14 mt-3 mx-4  md:mx-12'>
            <TitleComponent>Frequently Asked Questions</TitleComponent>
            <div className='w-full'>
                <div classNames='flex flex-wrap justify-center gap-4 pt-6' className='masonry sm:masonry-sm md:masonry-md gap-4 pt-6'>
                    {items.map((item) => (
                        <AccordionItemComponent item={item} key={item.id} className=" break-inside mb-7" />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default FAQComponent