import React from 'react'

function ShieldIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" focusable="false" viewBox="0 0 24 24" width={30} height={30} color="rgb(255, 255, 255)" fill='#fff'>
            <path d="M10.5 13H8v-3h2.5V7.5h3V10H16v3h-2.5v2.5h-3V13zM12 2L4 5v6.09c0 5.05 3.41 9.76 8 10.91 4.59-1.15 8-5.86 8-10.91V5l-8-3z">
            </path>
        </svg>
    )
}

export default ShieldIcon