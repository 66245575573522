import { AnimatePresence, motion } from 'framer-motion';
import React from 'react'

function AccordionItemComponent({ item, className }) {
    const [open, setOpen] = React.useState(false);
    return (
        <div className={`flex flex-col border border-gray-300 bg-gray-100 rounded-[16px] p-1 w-full h-fit ` + className}>
            <div onClick={() => setOpen(!open)} className='flex flex-row items-center justify-between cursor-pointer p-3'>
                <span className='text-lg'>{item.title}</span>
                <div className='bg-primary-600 p-2 rounded-full ml-2'>
                    <img className='' src={open ? "images/minus.svg" : "images/plus.svg"} alt="" />
                </div>
            </div>
            <AnimatePresence initial={false}>
                {open && (
                    <motion.div
                        key="content"
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: "auto", opacity: 1 }}
                        exit={{ height: 0, opacity: 0 }}
                        transition={{ duration: 0.3, ease: "easeInOut" }}
                        className="overflow-hidden text-gray-700 text-lg"
                    >
                        <div className='p-3'>{item.text}</div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    )
}

export default AccordionItemComponent